<template>
  <v-autocomplete
    outlined
    :label="input.label || null"
    hide-details="auto"
    :multiple="input.multiple || false"
    :items="mappedItems"
    v-model="model"
  />
</template>

<script>
export default {
  name: "Dropdown",
  props: {
    input: {
      type: Object,
      required: true,
    },
    value: [String, Object, Array],
  },
  data: () => ({
    model: null,
    search: "",
  }),
  computed: {
    mappedItems() {
      return this.input.values.map((item) =>
        typeof item === "string" ? item : item.name
      );
    },
  },
  watch: {
    model(newValue) {
      this.$emit("input", newValue);
    },
  },
};
</script>

<style scoped></style>
